@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-size: 16px;
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply text-main;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer base {
  :root {
    --screen-height: 100vh;
    --screen-height-without-nav: 100vh;
    --navbar-height: 125px;
    --scrolled-navbar-height: 80px;
    --breakpoint-sm: 640px;
    --breakpoint-md: 1024px;
    --breakpoint-lg: 1360px;
    --breakpoint-padding: 7%;
    --breakpoint-padding-sm: 2rem;
    --breakpoint-padding-md: 2rem;
    --breakpoint-padding-lg: 89px;

    --hero-height-ratio: 0.58;
    --hero-height: calc(var(--current-breakpoint) * var(--hero-height-ratio));
    --container-edge: var(--breakpoint-padding);
  }

  @media screen and (orientation: landscape) {
    :root {
      --video-width: calc(var(--screen-height) * 0.8);
      --video-height: calc(var(--screen-height) * 0.8);
    }
  }
  @media screen and (orientation: portrait) {
    :root {
      --video-width: calc(100vw * 0.8);
      --video-height: calc(100vw * 0.8);
    }
  }

  @media screen(sm) {
    :root {
      --current-breakpoint: calc(640px - var(--breakpoint-padding-sm));
      --container-edge: calc(
        ((100vw - 640px) / 2) + var(--breakpoint-padding-sm)
      );
    }
  }

  @media screen(md) {
    :root {
      --current-breakpoint: calc(1024px - var(--breakpoint-padding-sm));
      --container-edge: calc(
        ((100vw - 1024px) / 2) + var(--breakpoint-padding-md)
      );
    }
  }

  @media screen(lg) {
    :root {
      --current-breakpoint: calc(1360px - var(--breakpoint-padding-sm));
      --container-edge: calc(
        ((100vw - 1360px) / 2) + var(--breakpoint-padding-lg)
      );
    }
  }

  h1,
  .h1 {
    @apply text-4xl;
    @apply md:text-5xl;
    @apply text-[2.5rem];
  }
  h2,
  .h2 {
    @apply text-3xl;
    @apply md:text-4xl;
  }
  h3,
  .h3 {
    @apply text-2xl;
  }

  h4,
  .h4 {
    @apply text-xl;
  }

  h5,
  .h5 {
  }

  h6,
  .h6 {
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
  }

  p.p1 {
    @apply text-lg;
    @apply md:text-xl;
  }

  /**
  * Accordions
  */
  .accordion {
    --animation-duration: 0.5s;
    --collapsed-height: 47px;
    --item-count: 1;
    --panel-height: 10rem;
    min-height: calc(
      var(--collapsed-height) * var(--item-count) + var(--panel-height)
    );
  }

  .accordion-item .accordion-collapse-panel {
    @apply h-0;
    transition: height var(--animation-duration) ease-in-out;
    overflow: hidden;
  }

  .accordion-item .accordion-title-button-icon {
    transition: transform var(--animation-duration) ease-in-out;
  }

  .accordion-item.active .accordion-collapse-panel {
    @apply h-48;
  }

  .accordion-item.active .accordion-title-button-icon {
    @apply -rotate-180;
  }

  /**
  * Navigation Bar
  */
  .scrolled #navbar-wrapper {
    --navbar-height: 80px;
  }
  #navbar {
    --animation-duration: 0.5s;
    transition: transform var(--animation-duration) ease-in-out,
      background-color var(--animation-duration) ease-in-out,
      height var(--animation-duration) ease-in-out;
  }
  .scrolled #navbar {
    @apply bg-lighten;
    /*@apply backdrop-blur-sm;*/
  }
  .scrolled #navbar.hide {
    @apply -translate-y-[100%];
  }

  /**
  * Buttons Icons Roll Overs
  */
  .circled-icon {
    --animation-duration: 0.35s;
    transition: transform var(--animation-duration) ease-in-out,
      borders var(--animation-duration) ease-in-out,
      background-color var(--animation-duration) ease-in-out;
  }
  .circled-icon img,
  .circled-icon .split {
    transition: transform var(--animation-duration) ease-in-out;
  }
  .rotate-on-hover .circled-icon img {
    --animation-duration: 0.5s;
  }
  .split-on-hover:hover .circled-icon .split {
    @apply translate-x-1/4;
  }
  .split-on-hover:hover .circled-icon .split:last-child {
    @apply -translate-x-1/4;
  }
  .scale-on-hover:hover .circled-icon img {
    @apply scale-125;
  }
  .rotate-on-hover:hover .circled-icon img {
    @apply rotate-[270deg];
  }
  .diagonal-arrow-on-hover:hover .circled-icon img {
    @apply translate-x-[15%];
    @apply -translate-y-[15%];
  }
  .move-icon-to-right-on-hover:hover > .circled-icon {
    @apply translate-x-[25%];
  }
  .hide-label-on-scroll {
    --animation-duration: 0.35s;
  }
  .hide-label-on-scroll .label {
    transition: transform var(--animation-duration) ease-in-out;
  }
  .scrolled .hide-label-on-scroll .label {
    @apply translate-x-full;
  }

  /**
  * Forms
  */
  fieldset {
    --animation-duration: 0.35s;
    --field-padding-y: 0.5<div id= 'lets-connect' class=
      'fixed bottom-12 right-[7%] sm:right-[calc((100vw-640px)/2+(100vw*0.07))] md:right-[calc((100vw-1024px)/2+var(--breakpoint-padding-md))] lg:right-[calc((100vw-1360px)/2+var(--breakpoint-padding-lg))]'
      >…</div>rem;
    --field-padding-x: 0.75rem;
    @apply relative;
    @apply border;
    @apply border-neutral;
    @apply rounded-md;
    @apply w-full;
    @apply py-0;
    @apply my-0;
    @apply text-base;
    @apply outline-none;
    @apply mb-6;
    @apply px-[calc(var(--field-padding-x)*0.75)];
    transition: border-color var(--animation-duration) ease-in-out;
  }
  fieldset:focus {
    @apply border-main;
  }

  input, select {
    -webkit-appearance: none;
    appearance: none;
  }

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  textarea,
  select,
  fieldset button {
    box-sizing: border-box;
    @apply py-2;
    @apply px-[calc(var(--field-padding-x)*0.25)];
    @apply bg-transparent;
    @apply w-full;
    @apply z-10;
    @apply outline-none;
    /* 
  */
  }
  .select-arrow {
    transition: transform 0.15s ease-in-out;
  }
  select:focus~.select-arrow {
    /*@apply rotate-180;*/
  }
  
  
  input[type='text']::placeholder,
  input[type='email']::placeholder,
  input[type='tel']::placeholder {
    @apply opacity-0;
  }

  input[type='text']:focus,
  input[type='email']:focus,
  input[type='tel']:focus,
  select:focus {
    @apply outline-none;
    @apply border-main;
  }

  .jumping-label {
    pointer-events: auto;
    position: absolute;
    top: 50%;
    @apply left-[var(--field-padding-x)];
    transform: translate(0, -50%);
    transition: 0.3s all ease-in-out;
    border-radius: 100em;
    transform-origin: left;
    width: auto;
  }
  label {
    @apply pointer-events-none;
    @apply absolute;
    @apply inset-0;
    transition: color var(--animation-duration) ease-in-out;
  }
  #country-label {
    pointer-events: none;
  }
  input:not(:placeholder-shown)~label .jumping-label,
  textarea:not(:placeholder-shown)~label .jumping-label,/* Input has a value */
  input:focus~label .jumping-label,/* Input has focus */
  select:not(:placeholder-shown)~label .jumping-label /* Input has focus */ {
    top: 0;
    transform: translate(0, -50%) scale(0.75);
  }

  fieldset legend {
    width: 0;
    transition: width var(--animation-duration) ease-in-out,
      margin-left var(--animation-duration) ease-in-out,
      padding-right var(--animation-duration) ease-in-out;
    @apply text-[0.75em];
    @apply whitespace-nowrap;
    @apply my-0;
    @apply py-0;
    line-height: 0;
    opacity: 0;
  }

  input:not(:placeholder-shown)~legend,/* Input has a value */
  input:focus~legend,/* Input has focus */
  textarea:not(:placeholder-shown)~legend,/* Input has a value */
  textarea:focus~legend,/* Input has focus */
  select:not(:placeholder-shown)~legend /* Input has focus */ {
    @apply pr-[calc(var(--field-padding-x)*0.5)];
    @apply w-auto;
  }

  /**
  * remove autocomplete background
  */
  input:autofill,
  input:autofill:hover,
  input:autofill:focus,
  input:autofill:active,
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  select:autofill,
  select:autofill:hover,
  select:autofill:focus,
  select:autofill:active,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus,
  select:-webkit-autofill:active {
    outline: none;
    background-color: rgba(255, 255, 255, 0) !important;
    -webkit-box-shadow: none !important;
    transition: background-color 5000000s ease-in-out 0s;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
  input[data-autocompleted] {
    background-color: transparent !important;
  }
  input[type='submit'] {
    @apply cursor-pointer;
    @apply bg-main;
    @apply text-white;
    @apply px-6;
    @apply py-3;
    @apply rounded-full;
    transition: background-color var(--animation-duration) ease-in-out;
    @apply uppercase;
  }
  input[type='submit']:hover {
    @apply bg-main;
    @apply bg-opacity-80;
  }
}
